import { useContext, useEffect, useState } from "react"

import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material"
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog"
import { PickemFunctionsContext, PickemModalContext, PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import {
  awardDailyPickemPoints,
  type DailyPickemGroupAny,
  type DailyPickemGroupModified,
  type DailyPickemGroupNew,
  saveDailyPickem,
  type DailyPickemAny,
  type DailyPickemModified,
  type DailyPickemNew,
} from "services/Taiyoro/Pickem/daily"

import { DailyPickemGroupModal } from "./DailyPickemGroupModal"
import { DailyPickemList } from "./DailyPickemList"
import { DailyPickemModal } from "./DailyPickemModal"

export const DailyPickemSettings = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")

  const [isPickemModalOpen, setIsPickemModalOpen] = useState(false)
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false)

  const {
    dailyPickemList,
    setDailyPickemList,
    dailyPickemGroupList,
    setDailyPickemGroupList,
    refreshData,
    setSaving,
    loading,
    eventState,
  } = useContext(PickemSettingsContext)

  const [selectedDailyPickem, setSelectedDailyPickem] = useState<DailyPickemAny | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<DailyPickemGroupAny | null>(null)
  const [prefilledData, setPrefilledData] = useState<{
    dateId?: string
    groupId?: string
    priority?: number
  } | null>(null)
  const [prefilledGroupData, setPrefilledGroupData] = useState<{
    dateId?: string
    priority?: number
  } | null>(null)

  const [correctAnswerModalOpen, setCorrectAnswerModalOpen] = useState(false)
  const [correctAnswerModalAnswer, setCorrectAnswerModalAnswer] = useState("")
  const [correctAnswerModalAnswerContent, setCorrectAnswerModalAnswerContent] = useState("")
  const [correctAnswerModalPickemId, setCorrectAnswerModalPickemId] = useState("")

  const [awardPointsModalOpen, setAwardPointsModalOpen] = useState(false)
  const [awardPointsModalPickemId, setAwardPointsModalPickemId] = useState("")

  const [selectedDateTaiyoroRating, setSelectedDateTaiyoroRating] = useState<number | undefined>(undefined)

  const addDailyPickem = (dailyPickem: DailyPickemNew) => {
    setDailyPickemList([...dailyPickemList, dailyPickem])
  }

  const addGroup = (group: DailyPickemGroupNew) => {
    setDailyPickemGroupList([...dailyPickemGroupList, group])
  }

  const updateDailyPickem = (dailyPickem: DailyPickemModified | DailyPickemNew) => {
    const newDailyPickemList = [...dailyPickemList]
    newDailyPickemList[dailyPickemList.findIndex((value) => value.id === dailyPickem.id)] = dailyPickem
    setDailyPickemList(newDailyPickemList)
  }

  const updateGroup = (group: DailyPickemGroupModified | DailyPickemGroupNew) => {
    const newDailyPickemGroupList = [...dailyPickemGroupList]
    newDailyPickemGroupList[dailyPickemGroupList.findIndex((value) => value.id === group.id)] = group
    setDailyPickemGroupList(newDailyPickemGroupList)
  }

  const deleteDailyPickem = (dailyPickemId: string) => {
    const dailyPickemStatus = dailyPickemList.find((value) => value.id === dailyPickemId)?.status

    if (!dailyPickemStatus) return

    if (dailyPickemStatus === "saved" || dailyPickemStatus === "modified") {
      const newDailyPickemList = [...dailyPickemList]
      newDailyPickemList[dailyPickemList.findIndex((value) => value.id === dailyPickemId)].status =
        dailyPickemStatus === "saved" ? "deletedFromSaved" : "deletedFromModified"
      setDailyPickemList(newDailyPickemList)
    }

    if (dailyPickemStatus === "new") {
      const newDailyPickemList = dailyPickemList.filter((value) => value.id !== dailyPickemId)
      setDailyPickemList(newDailyPickemList)
    }
  }

  const deleteGroup = (groupId: string) => {
    const groupStatus = dailyPickemGroupList.find((value) => value.id === groupId)?.status

    if (!groupStatus) return

    if (groupStatus === "saved" || groupStatus === "modified") {
      const newDailyPickemGroupList = [...dailyPickemGroupList]
      newDailyPickemGroupList[dailyPickemGroupList.findIndex((value) => value.id === groupId)].status =
        groupStatus === "saved" ? "deletedFromSaved" : "deletedFromModified"
      setDailyPickemGroupList(newDailyPickemGroupList)
    }

    if (groupStatus === "new") {
      const newDailyPickemGroupList = dailyPickemGroupList.filter((value) => value.id !== groupId)
      setDailyPickemGroupList(newDailyPickemGroupList)
    }

    const pickemsInGroup = dailyPickemList.filter((pickem) => pickem.groupId === groupId)
    const newDailyPickemList = [...dailyPickemList]
    pickemsInGroup.forEach((pickem) => {
      newDailyPickemList[dailyPickemList.findIndex((value) => value.id === pickem.id)].groupId = null
    })
    setDailyPickemList(newDailyPickemList)
  }

  const restoreDailyPickem = (dailyPickemId: string) => {
    const dailyPickemStatus = dailyPickemList.find((value) => value.id === dailyPickemId)?.status

    if (!dailyPickemStatus) return

    const newDailyPickemList = [...dailyPickemList]
    newDailyPickemList[dailyPickemList.findIndex((value) => value.id === dailyPickemId)].status =
      dailyPickemStatus === "deletedFromSaved" ? "saved" : "modified"
    setDailyPickemList(newDailyPickemList)
  }

  const restoreGroup = (groupId: string) => {
    const groupStatus = dailyPickemGroupList.find((value) => value.id === groupId)?.status

    if (!groupStatus) return

    const newDailyPickemGroupList = [...dailyPickemGroupList]
    newDailyPickemGroupList[dailyPickemGroupList.findIndex((value) => value.id === groupId)].status =
      groupStatus === "deletedFromSaved" ? "saved" : "modified"
    setDailyPickemGroupList(newDailyPickemGroupList)
  }

  const editDailyPickem = (dailyPickemId: string) => {
    const selected = dailyPickemList.find((value) => value.id === dailyPickemId)
    if (!selected) return

    setIsPickemModalOpen(true)
    setSelectedDailyPickem(selected)
  }

  const editGroup = (groupId: string) => {
    const selected = dailyPickemGroupList.find((value) => value.id === groupId)
    if (!selected) return

    setIsGroupModalOpen(true)
    setSelectedGroup(selected)
  }

  const stageCorrectAnswer = (
    dailyPickemId: string,
    answer: {
      id: string
      content: string
      contentJa: string
    }
  ) => {
    setCorrectAnswerModalPickemId(dailyPickemId)
    setCorrectAnswerModalOpen(true)
    setCorrectAnswerModalAnswer(answer.id)
    setCorrectAnswerModalAnswerContent(
      language === "ja" && answer.contentJa && answer.contentJa !== "" ? answer.contentJa : answer.content
    )
  }

  const setCorrectAnswer = async (dailyPickemId: string, correctAnswer: string) => {
    if (loading) return

    const pickem = dailyPickemList.find((value) => value.id === dailyPickemId)
    if (!pickem) return

    const dailyPickem = {
      ...dailyPickemList[dailyPickemList.findIndex((value) => value.id === dailyPickemId)],
    }
    if (dailyPickem.status !== "saved") {
      return
    }

    setSaving(true)

    dailyPickem.correctAnswer = correctAnswer

    await saveDailyPickem({ dailyPickem })
      .then((response) => {
        if (response.status === "success") {
          void refreshData()
        } else {
          console.error("An error occurred while saving the correct answer.")
        }
      })
      .finally(() => {
        setCorrectAnswerModalOpen(false)
        setSaving(false)
      })
  }

  const stageAwardPoints = (dailyPickemId: string) => {
    if (!eventState) return

    const pickem = dailyPickemList.find((pickem) => pickem.id === dailyPickemId)
    if (!pickem) return

    const date = eventState.dates.find((date) => date.id === pickem.dateId)
    if (!date) return

    setSelectedDateTaiyoroRating(date.taiyoroRating)

    setAwardPointsModalOpen(true)
    setAwardPointsModalPickemId(dailyPickemId)
  }

  const awardPoints = async (dailyPickemId: string) => {
    if (loading) return

    setSaving(true)

    await awardDailyPickemPoints({ dailyPickemId })
      .then((response) => {
        if (response.status === "success") {
          void refreshData()
        } else {
          console.error("An error occurred while awarding points.")
        }
      })
      .finally(() => {
        setSaving(false)
        setAwardPointsModalOpen(false)
      })
  }

  const moveGroupUp = (groupId: string) => {
    const group = dailyPickemGroupList.find((group) => group.id === groupId)
    if (!group || group.status === "deletedFromModified" || group.status === "deletedFromSaved") return

    const { dateId } = group
    const previousGroup = dailyPickemGroupList.find(
      (pGroup) => pGroup.dateId === dateId && pGroup.priority === group.priority - 1
    )

    const newDailyPickemGroupList = [...dailyPickemGroupList]
    newDailyPickemGroupList[dailyPickemGroupList.findIndex((value) => value.id === groupId)] = {
      ...group,
      status: group.status === "new" ? "new" : "modified",
      priority: Math.max(group.priority - 1, 1),
    }

    if (previousGroup) {
      newDailyPickemGroupList[dailyPickemGroupList.findIndex((value) => value.id === previousGroup.id)] = {
        ...previousGroup,
        status: previousGroup.status === "new" ? "new" : "modified",
        priority: previousGroup.priority + 1,
      }
    }

    setDailyPickemGroupList(newDailyPickemGroupList)
  }

  const moveGroupDown = (groupId: string) => {
    const group = dailyPickemGroupList.find((group) => group.id === groupId)
    if (!group || group.status === "deletedFromModified" || group.status === "deletedFromSaved") return

    const { dateId } = group
    const nextGroup = dailyPickemGroupList.find(
      (nGroup) => nGroup.dateId === dateId && nGroup.priority === group.priority + 1
    )

    const newDailyPickemGroupList = [...dailyPickemGroupList]
    newDailyPickemGroupList[dailyPickemGroupList.findIndex((value) => value.id === groupId)] = {
      ...group,
      status: group.status === "new" ? "new" : "modified",
      priority: Math.max(group.priority + 1, 0),
    }

    if (nextGroup) {
      newDailyPickemGroupList[dailyPickemGroupList.findIndex((value) => value.id === nextGroup.id)] = {
        ...nextGroup,
        status: nextGroup.status === "new" ? "new" : "modified",
        priority: nextGroup.priority - 1,
      }
    }

    setDailyPickemGroupList(newDailyPickemGroupList)
  }

  const movePickemUp = (pickemId: string) => {
    const pickem = dailyPickemList.find((pickem) => pickem.id === pickemId)
    if (!pickem || pickem.status === "deletedFromModified" || pickem.status === "deletedFromSaved") return

    const { dateId } = pickem
    const previousPickem = dailyPickemList.find(
      (pPickem) =>
        pPickem.dateId === dateId &&
        pickem.priority &&
        pPickem.priority === pickem.priority - 1 &&
        pPickem.groupId === pickem.groupId
    )

    const newDailyPickemList = [...dailyPickemList]
    newDailyPickemList[dailyPickemList.findIndex((value) => value.id === pickemId)] = {
      ...pickem,
      status: pickem.status === "new" ? "new" : "modified",
      priority: pickem.priority !== null ? Math.max(pickem.priority - 1, 0) : 0,
    } as DailyPickemModified

    if (previousPickem) {
      newDailyPickemList[dailyPickemList.findIndex((value) => value.id === previousPickem.id)] = {
        ...previousPickem,
        status: previousPickem.status === "new" ? "new" : "modified",
        priority: previousPickem.priority! + 1,
      } as DailyPickemModified
    }

    setDailyPickemList(newDailyPickemList)
  }

  const movePickemDown = (pickemId: string) => {
    const pickem = dailyPickemList.find((pickem) => pickem.id === pickemId)
    if (!pickem || pickem.status === "deletedFromModified" || pickem.status === "deletedFromSaved") return

    const { dateId } = pickem
    const nextPickem = dailyPickemList.find(
      (nPickem) =>
        nPickem.dateId === dateId &&
        pickem.priority &&
        nPickem.priority === pickem.priority + 1 &&
        nPickem.groupId === pickem.groupId
    )

    const newDailyPickemList = [...dailyPickemList]
    newDailyPickemList[dailyPickemList.findIndex((value) => value.id === pickemId)] = {
      ...pickem,
      status: pickem.status === "new" ? "new" : "modified",
      priority: pickem.priority !== null ? Math.max(pickem.priority + 1, 0) : 0,
    } as DailyPickemModified

    if (nextPickem) {
      newDailyPickemList[dailyPickemList.findIndex((value) => value.id === nextPickem.id)] = {
        ...nextPickem,
        status: nextPickem.status === "new" ? "new" : "modified",
        priority: nextPickem.priority! - 1,
      } as DailyPickemModified
    }

    setDailyPickemList(newDailyPickemList)
  }

  const maxRewardEvent = dailyPickemList.reduce((acc, curr) => acc + curr.reward, 0)

  return (
    <PickemFunctionsContext.Provider
      value={{
        addGroup,
        updateGroup,
        editGroup,
        deleteGroup,
        restoreGroup,
        addDailyPickem,
        updateDailyPickem,
        editDailyPickem,
        deleteDailyPickem,
        restoreDailyPickem,
        stageCorrectAnswer,
        stageAwardPoints,
        moveGroupUp,
        moveGroupDown,
        movePickemUp,
        movePickemDown,
      }}
    >
      <PickemModalContext.Provider
        value={{
          setSelectedGroup,
          setPrefilledGroupData,
          setIsGroupModalOpen,
          setSelectedDailyPickem,
          setPrefilledData,
          setIsPickemModalOpen,
        }}
      >
        <Box
          sx={{ width: "100%" }}
          mt={2}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typography
                variant="h5"
                component="h3"
                mt={3}
              >
                {t("eventPickem.daily.subtitle")}
              </Typography>
              <Typography
                mb={3}
                sx={{ fontSize: "0.875rem" }}
              >
                {t("eventPickem.daily.maxRewardEvent")}: <strong>{maxRewardEvent}</strong>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={2}
            >
              <Tooltip title={t("eventPickem.daily.newGroupTooltip")}>
                <Button
                  size="medium"
                  variant="contained"
                  color="warning"
                  aria-label="add"
                  onClick={() => {
                    setSelectedGroup(null)
                    setPrefilledGroupData(null)
                    setIsGroupModalOpen(true)
                  }}
                  sx={{
                    flexShrink: 0,
                  }}
                >
                  {t("eventPickem.daily.newGroup")}
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginLeft: "8px" }}
                  />
                </Button>
              </Tooltip>
              <Tooltip title={t("eventPickem.daily.newPickemTooltip")}>
                <Button
                  size="medium"
                  variant="contained"
                  color="success"
                  aria-label="add"
                  onClick={() => {
                    setSelectedDailyPickem(null)
                    setPrefilledData(null)
                    setIsPickemModalOpen(true)
                  }}
                  sx={{
                    flexShrink: 0,
                  }}
                >
                  {t("eventPickem.daily.newPickem")}
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginLeft: "8px" }}
                  />
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
          <DailyPickemModal
            isOpen={isPickemModalOpen}
            onClose={() => {
              setSelectedDailyPickem(null)
              setPrefilledData(null)
              setIsPickemModalOpen(false)
            }}
            selectedDailyPickem={selectedDailyPickem}
            prefilledData={prefilledData}
            addDailyPickem={addDailyPickem}
            updateDailyPickem={updateDailyPickem}
          />
          <DailyPickemGroupModal
            isOpen={isGroupModalOpen}
            onClose={() => {
              setSelectedGroup(null)
              setPrefilledGroupData(null)
              setIsGroupModalOpen(false)
            }}
            selectedGroup={selectedGroup}
            prefilledGroupData={prefilledGroupData}
          />
          <DailyPickemList />
          <ConfirmationDialog
            open={correctAnswerModalOpen}
            cancelText={t("common:actions.cancel")}
            confirmText={t("common:actions.confirm")}
            onCancel={() => {
              setCorrectAnswerModalOpen(false)
              setCorrectAnswerModalPickemId("")
              setCorrectAnswerModalAnswer("")
              setCorrectAnswerModalAnswerContent("")
            }}
            onConfirm={() => {
              void setCorrectAnswer(correctAnswerModalPickemId, correctAnswerModalAnswer)
            }}
            title={t("eventPickem.daily.confirmationModal.titleCorrectAnswer")}
            body={t("eventPickem.daily.confirmationModal.bodyCorrectAnswer", {
              answer: correctAnswerModalAnswerContent,
            })}
          />
          <ConfirmationDialog
            open={awardPointsModalOpen}
            cancelText={t("common:actions.cancel")}
            confirmText={t("common:actions.confirm")}
            onCancel={() => {
              setAwardPointsModalOpen(false)
              setAwardPointsModalPickemId("")
            }}
            onConfirm={() => {
              void awardPoints(awardPointsModalPickemId)
            }}
            title={t("eventPickem.daily.confirmationModal.titleAwardPoints")}
            body={t("eventPickem.daily.confirmationModal.bodyAwardPoints", {
              answer: correctAnswerModalAnswerContent,
            })}
          >
            {selectedDateTaiyoroRating && selectedDateTaiyoroRating > 0 && (
              <Typography sx={{ mt: 1 }}>
                {t("eventPickem.daily.confirmationModal.rating", {
                  rating: (selectedDateTaiyoroRating / 20).toFixed(1),
                })}
              </Typography>
            )}
            {(!selectedDateTaiyoroRating || selectedDateTaiyoroRating < 0) && (
              <>
                <Typography sx={{ mt: 1, color: "red", fontWeight: "bold" }}>
                  {t("eventPickem.daily.confirmationModal.noRating")}
                </Typography>
                <Typography sx={{ mt: 1, color: "red", fontWeight: "bold" }}>
                  {t("eventPickem.daily.confirmationModal.scoreMultiplier")}
                </Typography>
              </>
            )}
          </ConfirmationDialog>
        </Box>
      </PickemModalContext.Provider>
    </PickemFunctionsContext.Provider>
  )
}

import React from "react"

import { Box, Stack } from "@mui/material"
import { DailyPickemSettings } from "components/Taiyoro/Pickem/DailyPickemSettings"
import { OgImageSettings } from "components/Taiyoro/Pickem/OgImageSettings"
import { PickemSettingsProvider } from "components/Taiyoro/Pickem/PickemProvider"
import { PrizeSettings } from "components/Taiyoro/Pickem/PrizeSettings"
import SaveData from "components/Taiyoro/Pickem/SaveData"
import { SettingsOptions } from "components/Taiyoro/Pickem/SettingsOptions"
import { SettingsWrapper } from "components/Taiyoro/Pickem/SettingsWrapper"
import { VisibilitySettings } from "components/Taiyoro/Pickem/VisibilitySettings"

const PickemSettingsPage = () => {
  return (
    <PickemSettingsProvider>
      <Box sx={{ height: "100%" }}>
        <SettingsWrapper>
          <SettingsOptions />
          <VisibilitySettings />
          <OgImageSettings />
          <PrizeSettings />
          <DailyPickemSettings />
          <Stack
            direction="row"
            justifyContent="flex-end"
            mt={4}
          >
            <SaveData />
          </Stack>
        </SettingsWrapper>
      </Box>
    </PickemSettingsProvider>
  )
}

export default PickemSettingsPage

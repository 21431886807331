import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, FormControlLabel, Stack, Switch, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Props {
  value: {
    isPublic: 1 | 0
    isDraft: 1 | 0
  }
  updateValuePublic: (value: 1 | 0) => void
  updateValueDraft: (value: 1 | 0) => void
  updateStatus?: () => void
}

export const StatusControl = ({ value, updateValuePublic, updateValueDraft, updateStatus }: Props) => {
  const { t } = useTranslation("taiyoro")

  return (
    <Box
      sx={{
        border: "1px solid rgba(125,125,125,0.5)",
        borderRadius: "4px",
        paddingInline: "12px",
        paddingBlock: "4px",
      }}
    >
      <Typography
        variant="body1"
        component="h5"
      >
        {t("eventPickem.daily.modal.status")}
      </Typography>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FormControlLabel
            control={
              <Switch
                aria-label="make public"
                checked={value.isPublic === 1}
                onChange={(event) => {
                  updateValuePublic(event.target.checked ? 1 : 0)
                  updateStatus?.()
                }}
              />
            }
            label={t("eventPickem.daily.modal.public")}
          />
          <Tooltip
            title={t("eventPickem.daily.modal.publicTooltip")}
            enterDelay={0}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Tooltip>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FormControlLabel
            control={
              <Switch
                aria-label="make draft mode"
                checked={value.isDraft === 1}
                onChange={(event) => {
                  updateValueDraft(event.target.checked ? 1 : 0)
                  updateStatus?.()
                }}
              />
            }
            label={t("eventPickem.daily.modal.draft")}
          />
          <Tooltip
            title={t("eventPickem.daily.modal.draftTooltip")}
            enterDelay={0}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Tooltip>
        </Stack>
      </Stack>
    </Box>
  )
}

import GraphQL from "utils/graphql"

import type { APIResponse, APIResponseNoData } from "./types/common"

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

const endpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

const runQuery = async (query: string) =>
  await GraphQL(
    query,
    undefined,
    undefined,
    undefined,
    (err) => {
      console.log(err)
      throw err
    },
    endpoint
  )

type TournamentPickem = {
  gameId: string
  isActive: boolean
}

export type Settings = {
  id: string
  eventId: string
  isPublic: boolean
  prizeBannerCopy: string
  prizeBannerCopyJa: string
  prizeBannerImageUrl: string
  prizePageCopy: string
  prizePageCopyJa: string
  prizePageImageUrl: string
  tournamentPickem: Array<TournamentPickem>
  ogImageUrl: string
}

export type SettingsResponse = {
  id: string
  eventId: string
  isPublic: 1 | 0
  prizeBannerCopy: string | null
  prizeBannerCopyJa: string | null
  prizeBannerImageUrl: string | null
  prizePageCopy: string | null
  prizePageCopyJa: string | null
  prizePageImageUrl: string | null
  tournamentPickem: Array<TournamentPickem> | null
  ogImageUrl: string | null
}

export type FetchPickemSettingsResponse = APIResponse<SettingsResponse | null>

type FetchPickemSettingsParams = {
  eventId: string
}

export const fetchPickemSettings = async ({
  eventId,
}: FetchPickemSettingsParams): Promise<FetchPickemSettingsResponse> => {
  try {
    const query = `query {
      pickem {
        get(eventId: "${eventId}") {
          id
          eventId
          isPublic
          prizeBannerCopy
          prizeBannerCopyJa
          prizeBannerImageUrl
          prizePageCopy
          prizePageCopyJa
          prizePageImageUrl
          ogImageUrl
          tournamentPickem {
            gameId
            isActive
          }
        }
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.get && {
        status: "success",
        data: response.data.pickem.get,
      }) || {
        status: "success",
        data: null,
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}

type SavePickemSettingsParams = Partial<Settings> & { eventId: string }

export const savePickemSettings = async ({
  id,
  eventId,
  isPublic,
  prizeBannerCopy,
  prizeBannerCopyJa,
  prizeBannerImageUrl,
  prizePageCopy,
  prizePageCopyJa,
  prizePageImageUrl,
  ogImageUrl,
}: SavePickemSettingsParams): Promise<APIResponseNoData> => {
  try {
    const query = `mutation {
      pickem {
        savePickem(pickem: {
          ${id !== undefined && id !== "" ? `id: "${id}",` : ""}
          eventId: "${eventId}",
          ${isPublic !== undefined ? `isPublic: ${isPublic ? 1 : 0},` : ""}
          ${prizeBannerCopy && prizeBannerCopy !== "" ? `prizeBannerCopy: ${JSON.stringify(prizeBannerCopy)},` : `prizeBannerCopy: null`}
          ${prizeBannerCopyJa && prizeBannerCopyJa !== "" ? `prizeBannerCopyJa: ${JSON.stringify(prizeBannerCopyJa)},` : `prizeBannerCopyJa: null`}
          ${prizeBannerImageUrl && prizeBannerImageUrl !== "" ? `prizeBannerImageUrl: "${prizeBannerImageUrl}",` : `prizeBannerImageUrl: null`}
          ${prizePageCopy && prizePageCopy !== "" ? `prizePageCopy: ${JSON.stringify(prizePageCopy)},` : `prizePageCopy: null`}
          ${prizePageCopyJa && prizePageCopyJa !== "" ? `prizePageCopyJa: ${JSON.stringify(prizePageCopyJa)},` : `prizePageCopyJa: null`}
          ${prizePageImageUrl && prizePageImageUrl !== "" ? `prizePageImageUrl: "${prizePageImageUrl}",` : `prizePageImageUrl: null`}
          ${ogImageUrl && ogImageUrl !== "" ? `ogImageUrl: "${ogImageUrl}",` : `ogImageUrl: null`}
        }) {
          id
        }
      }
    }`

    const response = await runQuery(query)

    return (
      (response?.data?.pickem?.savePickem && {
        status: "success",
      }) || {
        status: "error",
      }
    )
  } catch (e) {
    return {
      status: "error",
    }
  }
}

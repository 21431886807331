import { useContext } from "react"

import { Box, Stack, Typography } from "@mui/material"
import MediaUpload from "components/Taiyoro/MediaUpload"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import type { Settings } from "services/Taiyoro/Pickem/settings"

export const OgImageSettings = () => {
  const { t } = useTranslation("taiyoro")

  const { settings, setSettings } = useContext(PickemSettingsContext)

  const handleAddImage = (field: keyof Pick<Settings, "ogImageUrl">, url: string) => {
    setSettings((prevSettings) => {
      const updatedSettings: Partial<Settings> = {
        [field]: url,
      }
      return { ...prevSettings, ...updatedSettings }
    })
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stack gap={2}>
        <Typography
          variant="h5"
          component="h3"
          mt={3}
          mb={3}
        >
          {t("eventPickem.ogImage.subtitle")}
        </Typography>
        <Box sx={{ maxWidth: "600px" }}>
          <MediaUpload
            key="pickem-og-image"
            url={settings.ogImageUrl}
            mediaType="pickem_og_image"
            onChange={(url: string) => handleAddImage("ogImageUrl", url)}
            editable
            buttonText={t("eventPickem.ogImage.upload")}
            aspect={40 / 21}
          />
        </Box>
      </Stack>
    </Box>
  )
}
